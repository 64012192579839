import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Layout from "../../../components/Layout/layout"
import Seo from "../../seo"
import "../../../css/twilio_phone.css"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"
import DataHandlingAutomation from "../Doc/DataHandlingAutomation"
import MoreOptions from "./MoreOptions"
import UserExperienceFlow from "./UserExperienceFlow"
import { setAutomationOptionsArray, setAdditionalOptionsArray } from "./Service/phoneHelper"
import PanelToggle from "../Item/panelToggle"

const Detail = ({ selectedPhoneId }) => {
  const [phoneName, setPhoneName] = useState(""),
    [driveType, setDriveType] = useState(""),
    [DHAtuomationData, setDHAtuomationData] = useState([]),
    [initialAutomationOption, setInitialAutomationOption] = useState(""),
    [initialIncomingOpt, setInitialIncomingOpt] = useState(""),
    [initialFolder, setInitialFolder] = useState(""),
    [initialAsA, setInitialAsA] = useState(""),
    [displayInFeedTag, setDisplayInFeedTag] = useState([]),
    [displayInFeedTempTags, setDisplayInFeedTempTags] = useState([]),
    [contactTag, setContactTag] = useState([]),
    [contactTempTags, setContactTempTags] = useState([]),
    [primaryEmail, setPrimaryEmail] = useState(""),
    [tempTags, setTempTags] = useState([]),
    [docType, setDocType] = useState(""),
    [tags, setTags] = useState([]),
    [language, setLanguage] = useState(""),
    [recordingIs, setRecordingIs] = useState(false),
    [recordingOpts, setRecordingOpts] = useState(""),
    [missedCall, setMissedCall] = useState(false),
    [missedCallCounterInput, setMissedCallCounterInput] = useState(""),
    [spamCallerChallenge, setSpamCallerChallenge] = useState(false),
    [saveLoading, setSaveLoading] = useState(false),
    [whisper, setWhisper] = useState(""),
    [playAudio, setPlayAudio] = useState(""),
    [audioMp3, setAudioMp3] = useState(""),
    [whisperSmsInput, setWhisperSmsInput] = useState(""),
    [whisperSayInput, setWhisperSayInput] = useState(""),
    [additionalOptions, setAdditionalOptions] = useState([]),
    [phoneDescription, setPhoneDescription] = useState(""),
    [driveAudio, setDriveAudio] = useState([])

  const [states, setStates] = useState({
    driveType: "phone",
    showDhaPanel: true,
    showMoreDetailsPanel: true,
    showForThemPanel: true,
  })

  const getPhoneData = async selectedPhoneId => {
    await AxiosInstance.get(`/drive/phone/detail/${selectedPhoneId}`).then(response => {
      fillDocData(response.data.phone)
    })
  }

  const getDriveAudioList = () => {
    AxiosInstance.get(`/drive/list`, {
      params: {
        type: "audio",
      },
    }).then(response => {
      setDriveAudio(response.data.items)
    })
  }

  const fillDocData = phoneDetail => {
    setPhoneName(phoneDetail.driveAll.nameDisplay)
    setDriveType(phoneDetail.driveAll.type)
    setDHAtuomationData(phoneDetail.driveForm?.dhAutomation?.automation_option ?? "")
    setInitialAutomationOption(phoneDetail.driveForm?.dhAutomation?.initial_automation_option ?? "")
    setInitialIncomingOpt(phoneDetail.driveForm?.dhAutomation?.initial_incoming_opt ?? "")
    setInitialAsA(phoneDetail.driveForm?.dhAutomation?.initial_as_a ?? "")
    setInitialFolder(phoneDetail.driveForm?.dhAutomation?.initial_folder ?? "")
    setDocType(phoneDetail.driveForm?.moreOptions?.doc_type ?? "")
    setTags(phoneDetail.driveForm?.moreOptions?.doc_private_tags ?? [])
    setTempTags(phoneDetail.driveForm?.moreOptions?.doc_private_tags ?? [])
    setLanguage(phoneDetail.driveForm?.moreOptions?.language ?? "")
    setRecordingIs(phoneDetail.driveForm?.moreOptions?.recording_is ?? "")
    setRecordingOpts(phoneDetail.driveForm?.moreOptions?.recording_opts ?? "")
    setMissedCall(phoneDetail.driveForm?.moreOptions?.missed_call ?? "")
    setMissedCallCounterInput(
      phoneDetail.driveForm?.moreOptions?.missed_call_counter_input ??
        "Hi! We've noted your call but couldn't get to the phone. How can I help?"
    )
    setSpamCallerChallenge(phoneDetail.driveForm?.moreOptions?.spam_caller_challenge ?? "")
    setWhisper(phoneDetail.driveForm?.moreOptions?.whisper ?? "")
    setPlayAudio(phoneDetail.driveForm?.moreOptions?.play_audio ?? "")
    setAudioMp3(phoneDetail.driveForm?.moreOptions?.audiomp3 ?? "")
    setWhisperSmsInput(phoneDetail.driveForm?.moreOptions?.whisper_sms_input ?? "")
    setWhisperSayInput(phoneDetail.driveForm?.moreOptions?.whisper_sys_input ?? "")
    setAdditionalOptions(phoneDetail.driveForm?.ueFlow?.additional_options ?? "")
    setPhoneDescription(phoneDetail.details.phoneDescription)
  }

  const submitPhone = async e => {
    e.preventDefault()
    setSaveLoading(true)

    const formData = new FormData(e.target)
    let data = {
      docName: formData.get("docName"),
      content: "",
      jsonContent: "",
      ownerID: formData.get("ownerID"),
      parentDoc: formData.get("parentDoc"),
      phoneDescription: formData.get("phoneDescription"),
      form: {
        ueFlow: {
          additional_options: setAdditionalOptionsArray(formData),
        },
        dhAutomation: {
          initial_automation_option: formData.get("initial_automation_option"),
          initial_incoming_opt: formData.get("initial_incoming_opt"),
          initial_folder: formData.get("initial_folder"),
          initial_as_a: formData.get("initial_as_a"),
          automation_option: setAutomationOptionsArray(formData, displayInFeedTempTags, contactTempTags),
        },
        moreOptions: {
          doc_type: formData.get("docType"),
          doc_private_tags: tempTags,
          language: formData.get("language"),
          recording_is: formData.get("Recording_is"),
          recording_opts: formData.get("Recording_opts"),
          missed_call: formData.get("missed_call"),
          missed_call_counter_input: formData.get("missed_call_counter_input"),
          spam_caller_challenge: formData.get("spam_caller_challenge"),
          whisper: formData.get("whisper"),
          play_audio: formData.get("play_audio"),
          audiomp3: formData.get("audiomp3"),
          whisper_sms_input: formData.get("whisper_sms_input"),
          whisper_sys_input: formData.get("whisper_sys_input"),
        },
      },
    }
    savePhoneData(data)
  }

  const savePhoneData = data => {
    return new Promise(async () => {
      await AxiosInstance.post(`/drive/phone/edit/${selectedPhoneId}`, data)
        .then(function (response) {
          setSaveLoading(false)
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            console.log(error.response.data)
          } else if (error.response.data) {
            console.log(error.response.data.detail)
          }
        })
    })
  }

  useEffect(() => {
    getPhoneData(selectedPhoneId)
    getDriveAudioList()
  }, [])

  return (
    <Layout>
      {phoneName && <Seo title={phoneName} />}
      <Form onSubmit={submitPhone} id={"docForm"}>
        <div id="right-section" className="h-100 phone-detail-page">
          <div className="row g-0">
            <div className="col" id="page-urls">
              <div className="row g-0">
                <div className="col-lg-12 mb-3">
                  <div className="pageTitle_Editor">
                    <Link to="/drive" className="goBack">
                      <i className="bi bi-arrow-90deg-left" />
                    </Link>
                    <OverlayTrigger placement="right" overlay={<Tooltip>{phoneName}</Tooltip>}>
                      <span className="mobileTitlePhoneReadOnly" data-bs-toggle="tooltip">
                        <i className="bi bi-phone-fill" />
                      </span>
                    </OverlayTrigger>
                    <span className="titlePhoneReadOnly">{phoneName}</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone # Description"
                      name="phoneDescription"
                      defaultValue={phoneDescription}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-0">
                <div className="col-lg-12 mb-3">
                  <div className="row g-0">
                    {/* FOR THEM: User Experience Flow */}
                    <UserExperienceFlow
                      additionalOptions={additionalOptions}
                      setAdditionalOptions={setAdditionalOptions}
                      showForThemPanel={states.showForThemPanel}
                      driveAudio={driveAudio}
                    />
                    {/* FOR YOU: Data Handling & Automation */}

                    {/* 100% same as forms */}
                    <DataHandlingAutomation
                      DHAtuomationData={DHAtuomationData}
                      setDHAtuomationData={setDHAtuomationData}
                      initialAutomationOption={initialAutomationOption}
                      initialIncomingOpt={initialIncomingOpt}
                      initialFolder={initialFolder}
                      initialAsA={initialAsA}
                      setInitialAsA={setInitialAsA}
                      contactTag={contactTag}
                      setContactTag={setContactTag}
                      contactTempTags={contactTempTags}
                      setContactTempTags={setContactTempTags}
                      displayInFeedTag={displayInFeedTag}
                      setDisplayInFeedTag={setDisplayInFeedTag}
                      displayInFeedTempTags={displayInFeedTempTags}
                      setDisplayInFeedTempTags={setDisplayInFeedTempTags}
                      selectedDocId={selectedPhoneId}
                      primaryEmail={primaryEmail}
                      setPrimaryEmail={setPrimaryEmail}
                      driveType={driveType}
                      setInitialIncomingOpt={setInitialIncomingOpt}
                      setInitialFolder={setInitialFolder}
                      showDhaPanel={states.showDhaPanel}
                    />

                    {/* More Options */}
                    <MoreOptions
                      driveType={driveType}
                      docType={docType}
                      tags={tags}
                      language={language}
                      setTags={setTags}
                      tempTags={tempTags}
                      setTempTags={setTempTags}
                      recordingIs={recordingIs}
                      setRecordingIs={setRecordingIs}
                      recordingOpts={recordingOpts}
                      setRecordingOpts={setRecordingOpts}
                      missedCall={missedCall}
                      setMissedCall={setMissedCall}
                      missedCallCounterInput={missedCallCounterInput}
                      setMissedCallCounterInput={setMissedCallCounterInput}
                      spamCallerChallenge={spamCallerChallenge}
                      setSpamCallerChallenge={setSpamCallerChallenge}
                      whisper={whisper}
                      setWhisper={setWhisper}
                      playAudio={playAudio}
                      setPlayAudio={setPlayAudio}
                      audioMp3={audioMp3}
                      setAudioMp3={setAudioMp3}
                      whisperSmsInput={whisperSmsInput}
                      setWhisperSmsInput={setWhisperSmsInput}
                      whisperSayInput={whisperSayInput}
                      setWhisperSayInput={setWhisperSayInput}
                      showMoreDetailsPanel={states.showMoreDetailsPanel}
                      driveAudio={driveAudio}
                    />

                    <PanelToggle states={states} setStates={setStates} />

                    <div className="col-lg-12 text-center footer-action">
                      <button type="submit" className="btn btn-primary btn-lg">
                        <i className="bi bi-check2" /> Save &nbsp;
                        <i className={saveLoading ? "spinner-border spinner-border-sm" : ""} />
                      </button>{" "}
                      <button type="button" className="btn btn-secondary btn-lg">
                        <i className="bi bi-box-arrow-up-right" /> Preview
                      </button>{" "}
                      <OverlayTrigger placement="top" overlay={<Tooltip>History</Tooltip>}>
                        <button
                          type="button"
                          className="btn btn-default btn-lg btnOpenChatDock"
                          data-bs-toggle="tooltip"
                        >
                          <i className="bi bi-arrow-counterclockwise" />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Layout>
  )
}
export default Detail

import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import PhoneContactForm from "../PhoneContactForm"
import { ADD_NEW_CONTACT } from "../Service/phoneHelper"
import {
  DEFAULT_CONTACT_PHONE_OPTION,
  DEFAULT_CONTACT_ADDRESS_OPTION,
  PRIVACY_PUBLIC,
} from "../../../../services/globalStaticData"
import { CONTACT_TYPE_HUMAN } from "../../../Contacts/contactHelper"
import AxiosInstance from "../../../../services/axiosInstance"

const UserExpRow = ({ removeNewDataRow, dataRowIndex, driveAudio }) => {
  const [removeDataRow, setRemoveDataRow] = useState(false),
    [detailType, setDetailType] = useState(""),
    [isDisplayNewPerson, setIsDisplayNewPerson] = useState(false),
    [defaultSelectedRole, setDefaultSelectedRole] = useState(""),
    [defaultUserOptionSelected, setDefaultUserOptionSelected] = useState(ADD_NEW_CONTACT),
    [uploadedFile, setUploadedFile] = useState(null),
    [address, setAddress] = useState(""),
    [existingContactId, setExistingContactId] = useState(""),
    [selectedPhoneType, setSelectedPhoneType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
    [selectedAddressType, setSelectedAddressType] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.type),
    [contactData, setContactData] = useState([]),
    [contactName, setContactName] = useState(""),
    [contactEmail, setContactEmail] = useState(""),
    [companyName, setCompanyName] = useState(""),
    [jobTitle, setJobTitle] = useState(""),
    [contactPhone, setContactPhone] = useState(""),
    [existingUserDefaultName, setExistingUserDefaultName] = useState(""),
    [selectedRoleName, setSelectedRoleName] = useState(""),
    [existingContactImage, setExistingContactImage] = useState("")

  const saveContactData = async e => {
    if (isDisplayNewPerson) {
      let contactId

      if (defaultUserOptionSelected === ADD_NEW_CONTACT) {
        const contactFormData = {
          name: contactName,
          email: contactEmail,
          profilePic: uploadedFile,
          companyName: companyName,
          jobTitle: jobTitle,
          contactType: CONTACT_TYPE_HUMAN,
          phone: {
            type: selectedPhoneType,
            phone: contactPhone,
            permission: PRIVACY_PUBLIC,
          },
          address: {
            type: selectedAddressType,
            address: address,
            permission: PRIVACY_PUBLIC,
          },
          metaData: [],
          customFields: [],
        }

        const saveContactResponse = await AxiosInstance.post(`/contact/add`, contactFormData)
        contactId = saveContactResponse.data.contact
      } else {
        contactId = existingContactId
      }

      const newContactData = {
        contact_id: contactId,
        contact_name: existingUserDefaultName !== "" ? existingUserDefaultName : contactName,
        role_id: defaultSelectedRole,
        role_name: selectedRoleName,
        profile_pic: existingContactImage !== "" ? existingContactImage : uploadedFile,
      }

      setContactData([...contactData, newContactData])
    }
  }

  useEffect(() => {
    if (removeDataRow) {
      removeNewDataRow(dataRowIndex)
    }
  })

  return (
    <div className="pinkOutlineBox">
      <OverlayTrigger placement="top" overlay={<Tooltip>Remove</Tooltip>}>
        <button
          className="btnRemoveFTOption"
          data-bs-toggle="tooltip"
          onClick={() => {
            setRemoveDataRow(true)
          }}
        >
          <i className="bi bi-x-lg" />
        </button>
      </OverlayTrigger>

      <div className="row">
        <div className="col-lg-12">
          <div className="row row-cols-sm-auto g-2 forThemOptionContainer">
            <div className="col-12">
              <select
                className="form-select forThemOption"
                name={`additional_options_${dataRowIndex}`}
                onChange={e => setDetailType(e.target.value)}
              >
                <option>== Select ==</option>
                <option value="HearAudio">Hear Audio</option>
                <option value="GetForwarded">Get Forwarded</option>
                <option value="StartConversation">Start Conversation</option>
                <option value="RecordVoicemail">Record Voicemail</option>
              </select>
            </div>
            <div
              className={`col-12 forThemOpt forThemOptHearAudio${
                detailType !== "HearAudio" ? " d-none" : ""
              }`}
            >
              <select className="form-select" name={`audio_mp3_${dataRowIndex}`}>
                {driveAudio.map((audio, key) => (
                  <option value={audio.id} key={key}>
                    {audio.nameDisplay}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`col-12 forThemOpt forThemOptGetForwarded${
                detailType !== "GetForwarded" ? " d-none" : ""
              }`}
            >
              <div className="row row-cols-sm-auto align-items-center g-2">
                <div className="col-12">for</div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={5}
                    onkeypress="if (!window.__cfRLUnblockHandlers) return false; return onlyNumberKey(event)"
                    maxLength={3}
                    style={{ width: 50 }}
                    name={`get_forwarded_${dataRowIndex}`}
                  />
                </div>
                <div className="col-12">rings to</div>

                <div className="col-12 greenCallForwaringOpt greenCallForwaringOpt_CF1">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Add Contact</Tooltip>}>
                    <a
                      href="#"
                      className="btnSocializer"
                      data-bs-toggle="tooltip"
                      onClick={() => setIsDisplayNewPerson(!isDisplayNewPerson)}
                    >
                      <i className="bi bi-person-plus-fill" />
                    </a>
                  </OverlayTrigger>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <input
                    type="hidden"
                    name={`contact_data_${dataRowIndex}`}
                    value={JSON.stringify(contactData)}
                  />
                  {isDisplayNewPerson && (
                    <>
                      <PhoneContactForm
                        uploadedFile={uploadedFile}
                        setUploadedFile={setUploadedFile}
                        defaultSelectedRole={defaultSelectedRole}
                        setDefaultSelectedRole={setDefaultSelectedRole}
                        setIsDisplayNewPerson={setIsDisplayNewPerson}
                        setSelectedPhoneType={setSelectedPhoneType}
                        setSelectedAddressType={setSelectedAddressType}
                        existingContactId={existingContactId}
                        setExistingContactId={setExistingContactId}
                        defaultUserOptionSelected={defaultUserOptionSelected}
                        setDefaultUserOptionSelected={setDefaultUserOptionSelected}
                        address={address}
                        setAddress={setAddress}
                        setContactName={setContactName}
                        setContactEmail={setContactEmail}
                        setCompanyName={setCompanyName}
                        setJobTitle={setJobTitle}
                        setContactPhone={setContactPhone}
                        existingUserDefaultName={existingUserDefaultName}
                        setExistingUserDefaultName={setExistingUserDefaultName}
                        setSelectedRoleName={setSelectedRoleName}
                        setExistingContactImage={setExistingContactImage}
                      />

                      <button type="button" className="btn btn-primary" onClick={saveContactData}>
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`col-12 forThemOpt forThemOptRecordVoicemail${
                detailType !== "StartConversation" && detailType !== "RecordVoicemail" ? " d-none" : ""
              }`}
            >
              <div className="row row-cols-sm-auto g-2 align-items-center">
                <div className="col-12">
                  <select name={`conversion_play_audio_${dataRowIndex}`} className="form-select">
                    <option value="Play Audio">Play Audio</option>
                  </select>
                </div>
                <div className="col-12 greenCallForwaringPlayAudioSayOpt greenCallForwaringPlayAudioSayOptAudio">
                  <select className="form-select" name={`conversion_audio_mp3_${dataRowIndex}`}>
                    {driveAudio.map((audio, key) => (
                      <option value={audio.id} key={key}>
                        {audio.nameDisplay}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={`col-12${detailType === "StartConversation" ? " d-none" : ""}`}>
                  , then record voicemail.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserExpRow
